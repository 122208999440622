.systemOverviewHeader{
    margin-top: 20px;
    padding: 0px; 

    .systemOverviewTitle{
        font-size: 26px;
        text-align: start;
        margin: 0px;
        padding: 0px; 
        text-decoration: none;
        color: black;
    }

}

.listTable {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
    
table {
    border: 1px solid rgb(34, 88, 139);
    width: 200%;
    border-radius: 3px;
    
    // height: 200px;
}
    
th {
    border-bottom: 1px solid black;
    margin-left: 10px;
    margin-right: 10px;
}
    
td {
    text-align: center;
}

.cardBody {
    /* Add shadows to create the "card" effect */
    padding: 1%;
    margin: 1%;
    width: 145%;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    align-items: center;

    .cardContentTitle {
        font-size: 14px;
        text-align: center;
    }

    .cardContent{
        font-size: 44px;
        text-align: center;
    }
  }