.cardBody {
    /* Add shadows to create the "card" effect */
    padding: 1%;
    margin: 1%;
    height: 150px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    align-items: center;

    .cardContentTitle {
        font-size: 14px;
        text-align: center;
    }

    .cardContent{
        font-size: 44px;
        text-align: center;
    }
  }

.cardPadding{
    padding: 10px;
}

.sectionHeader{
    margin-top: 20px;
    padding: 0px; 

    .sectionTitle{
        font-size: 26px;
        text-align: start;
        margin: 0px;
        padding: 0px; 
        text-decoration: none;
        color: black;
    }
    a:hover {
        color: rgba(0, 99, 155, 1);
    }
}

.chartContainer{
    height: auto;
    width: 100%;
    padding: 1%;
    margin: 1%;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    align-items: center;

    .chartTitle{
        font-size: 20px;
        text-align: start;
        margin: 0px;
        padding: 0px; 
    }
}
.dashboardPieChartContainer {
    display: flex;
    width: 100%;
    margin: 1%;
    padding: 1%;
    .pieChartContainer {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        max-width: 350px;
        margin-right: 2%;
        padding: 1%;
        width: 100%;
        height: auto;
    
        .pieChartTitle{
            font-size: 14px;
            text-align: start;
            margin: 10px;
            padding: 5px; 
        }
    }
}

.cardOverallBody {
    /* Add shadows to create the "card" effect */
    height: auto;
    width: 100%;
    padding: 1%;
    margin: 1%;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    align-items: center;

    .cardContentOverallTitle {
        font-size: 24px;
        text-align: center;
    }

    .cardOverallContent{
        font-size: 84px;
        text-align: center;
    }

    
    
  }